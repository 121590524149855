<template lang="html">
    <v-tabs show-arrows flat light optional center-active v-if="change">
        <v-tab v-for="item in tabNav" :key="item.id" :to = "makePath(item)" >
                {{ item.titel }}
        </v-tab>
    </v-tabs>
</template>

<script>
import {loadTabNav,makePath} from '@/api/api'

export default {
  name: 'TabNav',
    data: () => ({
        tabNav:{},
        change:true
    }),
    methods: {
        fetchData() {
            this.change = false;
            if (this.$route.meta.type == "seite") {
                let sid = this.$route.params.id || 0;
                if (sid == 105 || sid == 104) {
                    sid = 0;
                }

                loadTabNav(sid).then(data =>{
                    this.tabNav = data;
                    this.change = true;
                })

            }else{
                loadTabNav(0).then(data =>{
                    this.tabNav = data;
                    this.change = true;
                })
            }
        },
        makePath
    },
    created() {
        this.fetchData();
    },
    watch: {
       $route: 'fetchData'
    }
}
</script>
